import { css } from 'styled-components'

export const flex = content => css`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: ${content};
  -ms-flex-pack: ${content};
  justify-content: ${content};
`

export const alignCenter = css`
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`

export const alignVertical = css`
  ${flex('center')};
  ${alignCenter};
  flex-flow: column;
`
export const centerize = css`
  ${flex('center')}
  ${alignCenter}
`
