import React from 'react'
import styled from 'styled-components'
import colors from '../tokens/colors'
import breakpoints from '../breakpoints'
import Drawer from '@material-ui/core/Drawer'
import Item from '../atoms/HeaderNavigationItem'

const Component = ({ data }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  return (
    <React.Fragment>
      <Menu>
        <Trigger onClick={toggleDrawer('right', true)}>
          <span></span>
          <span></span>
          <span></span>
        </Trigger>
      </Menu>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <Item data={data} />
      </Drawer>
    </React.Fragment>
  )
}

const Menu = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 40;
  cursor: pointer;
  margin-top: -4.5px;
  ${breakpoints.greaterThan('pc')`
    display: none;
  `}
`
const Trigger = styled.span`
  position: relative;
  display: block;
  width: 17px;
  height: 9px;
  > span {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors.cl_border};
    transition: 0.3s;
    .is-nev-opened & {
      background-color: ${colors.cl_white};
      height: 2px;
    }
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 4px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
    .is-nev-opened & {
      &:nth-of-type(1) {
        transform: translateY(3.5px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-3.5px) rotate(45deg);
      }
    }
  }
`

export default Component
