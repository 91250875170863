import React from 'react'
import Item from '../atoms/HeaderNavigationItem'
import styled from 'styled-components'
import breakpoints from '../breakpoints'
import { flex } from '../tokens/positionings'

const Component = ({ data }) => {
  return (
    <Gnav id="gnav">
      <GnavList>
        <Item data={data} />
      </GnavList>
    </Gnav>
  )
}

const Gnav = styled.nav`
  height: 100%;
`
const GnavList = styled.ul`
  ${flex('flex-start')}
  ${breakpoints.lessThan('pc')`
    display: none;
  `}
  height: 100%;
`
export default Component
