import React from 'react'
import { Link } from 'gatsby'
import Image from '../../svgs/logo.svg'

import styled from 'styled-components'

const Component = () => {
  return (
    <Logo>
      <Link to="./">
        <Image />
      </Link>
    </Logo>
  )
}

const Logo = styled.h1`
  width: 88px;
  padding: 9px 0;
`

export default Component
