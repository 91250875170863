import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import { alignVertical } from '../../tokens/positionings'
import { fixRatio } from '../../tokens/sizing'
import colors from '../../tokens/colors'
import { ButtonWithRightArrow } from '../../atoms/buttons/LinkButtons'

const Component = ({ data }) => {
  return (
    <Card>
      <Link to={'./offers/' + data.id}>
        <figure>
          <img src={data.image.url} alt={data.title} />
        </figure>
        <div>
          <h3>
            {data.title}
            <span>{data.method.title}</span>
          </h3>
          <ButtonWithRightArrow fill={colors.cl_red} link={'./offers/' + data.id}>
            {' '}
            More Info{' '}
          </ButtonWithRightArrow>
        </div>
      </Link>
    </Card>
  )
}

const Card = styled.li`
  width: calc(50% - 30px);
  margin-bottom: 60px;
  figure {
    ${fixRatio('1/1')};
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }
  ${breakpoints.lessThan('pc')`
  width: 100%;
  margin-bottom: 40px;
`}
  div {
    ${alignVertical};
    align-items: flex-start;
    background: ${colors.cl_white};
    width: 100%;
    padding: 20px;
    h3 {
      font-size: 1.8rem;
      text-align: left;
      span {
        display: block;
        padding-top: 4px;
        font-size: 1.4rem;
        margin-bottom: 12px;
      }
    }
  }
`
export default Component
