import React from 'react'
import C from '../../svgs/COPYRIGHT.svg'
import styled from 'styled-components'

import JobOffer from './FlexCards'
// import Instagram from '../modules/media/InstagramTiles'


const Component = ({ topflag }) => {
  return (
    <React.Fragment>
      <JobOffer topflag={topflag} />
      {/* <Instagram /> */}
      <Footer>
        <FooterInner>
          <C alt="COPYRIGHT HYAKURAKUSOU CO.,LTD ALL RIGHT RESERVED." />
        </FooterInner>
      </Footer>
    </React.Fragment>
  )
}

const Footer = styled.footer`
  padding: 36px 0;
  background: #212121;
  text-align: center;
`

const FooterInner = styled.div`
  width: 238px;
  margin: 0 auto;
`

export default Component
