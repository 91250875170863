import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './organisms/TheHeader'
import Footer from './organisms/TheFooter'
import styled from 'styled-components'

import 'typeface-fjalla-one'
import '../scss/style.scss'

const Layout = ({ children, topflag }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer topflag={topflag} />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = styled.main`
  margin-top: 50px;
`

export default Layout
