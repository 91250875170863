import PropTypes from 'prop-types'
import React from 'react'

import Logo from '../atoms/Logo'
import Hamburger from '../atoms/Hamburger'
import Navigation from '../modules/HeaderNavigation'
import styled from 'styled-components'
import breakpoints from '../breakpoints'
import colors from '../tokens/colors'
import { flex } from '../tokens/positionings'

const Component = ({ siteTitle }) => {
  const navData = [
    {
      text: 'ABOUT 100raku',
      link: 'about',
    },
    {
      text: 'WORKS & CAREERS',
      link: 'works',
    },
    {
      text: 'CEO MESSAGE',
      link: 'message',
    },
    {
      text: 'INTERVIERWS',
      link: 'interviews',
    },
    {
      text: 'OFFERS',
      link: 'offers',
    },
    {
      text: 'COMPANY',
      link: 'company',
    },
    {
      text: 'CONTACT',
      link: 'contact',
    },
  ]
  return (
    <Header id="header">
      <HeaderInn>
        <Logo />
        <Hamburger data={navData} />
        <Navigation data={navData} />
      </HeaderInn>
    </Header>
  )
}

Component.propTypes = {
  siteTitle: PropTypes.string,
}

Component.defaultProps = {
  siteTitle: ``,
}

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.cl_white};
  z-index: 40;
  ${breakpoints.lessThan('pc')`
    &::before {
      content: '';
      display: none;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 8;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(8px);
      .is-nev-opened & {
        display: block;
      }
    }
  `}
`
const HeaderInn = styled.div`
  height: 50px;
  padding-left: 20px;
  ${flex('space-between')}
  ${breakpoints.lessThan('pc')`
    height: auto;
    padding-left: 3%;
  `}
`

export default Component
