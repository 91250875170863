import React from 'react'
import styled, { css } from 'styled-components'

export const fixRatio = ratio => css`
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-top: calc(100% * ${ratio});
  }
`

export const restrictWidth = width => css`
  position: relative;
  max-width: ${width}px;
  margin: auto;
`

export const WidthRestructor = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
`
