import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import colors from '../tokens/colors'
import styled, { css } from 'styled-components'
import { flex } from '../tokens/positionings'
import Title from '../atoms/HeadingWithUnderbar'
import Card from '../modules/cards/CardWithButton'
import breakpoints from '../breakpoints'

const Component = ({ topflag }) => {
  const data = useStaticQuery(
    graphql`
      {
        allMicrocmsOffers {
          edges {
            node {
              id
              title
              method {
                title
              }
              image {
                url
              }
            }
          }
        }
      }
    `
  )
  let offers = []
  for (let offer of data.allMicrocmsOffers.edges) {
    offers.push(<Card data={offer.node} />)
  }
  return (
    <Section topflag={topflag}>
      <div>
        <Title title="Job Offers" subTitle="求人情報" margin={60} />
        <CardList>{offers}</CardList>
      </div>
    </Section>
  )
}

const Section = styled.section`
  text-align: center;
  background: #f2f2f2;
  padding: 80px 40px 0;
  ${props =>
    props.topflag === true
      ? css`
          margin-top: calc(50vw * 0.087488663525924 * -1 - 5px);
        `
      : css`
          border-top: 10px solid ${colors.cl_red};
        `}
  ${breakpoints.lessThan('pc')`
  padding 80px 0 0;
`}
> div {
    margin-bottom: -70px;
    padding: 80px 40px 120px;
    ${breakpoints.lessThan('pc')`
    padding 80px 0 120px;
  `}
  }
`
const CardList = styled.ul`
  ${flex('space-between')}
`

export default Component
