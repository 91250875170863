import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { DIN } from '../../tokens/fontStyles'
import ArrowIcon from '../../../svgs/arrow_white.svg'
import { ButtonHover } from '../../tokens/toggles'

export const ButtonWithRightArrow = props => {
  return (
    <RightArrowButton
      to={'./' + props.link}
      color={props.color}
      fill={props.fill}
    >
      {props.children}
      <StyledArrow />
    </RightArrowButton>
  )
}

const ButtonBaseStyle = ({ color, fill }) => css`
  ${DIN};
  display: inline-block;
  position: relative;
  padding: 15px 40px;
  background: ${fill ? fill : '#212121'};
  color: ${color ? color : '#FFFFFF'};
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  transition: 0.4s;
`

const RightArrowButton = styled(Link)`
  ${ButtonBaseStyle};
  ${ButtonHover}
`

const StyledArrow = styled(ArrowIcon)`
  position: absolute;
  top: 0;
  right: 4px;
  width: 24px;
  height: 92%;
  background: url(${ArrowIcon}) no-repeat center / contain;
`
