import { css } from 'styled-components'
import breakpoints from '../breakpoints'
import colors from '../tokens/colors'

export const FjallaOne = css`
  font-family: 'Fjalla One', sans-serif;
  letter-spacing: 0.2em;
`
export const DIN = css`
  font-family: 'DIN Condensed';
  letter-spacing: 0.2em;
`
export const HeadingBaseStyle = css`
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.4rem;
`
export const BigMessage = css`
  font-size: 3.6rem;
  line-height: 1.8em;
  text-align: center;
`
export const PageTitle = css`
  font-size: 6rem;
  font-weight: bold;
  ${DIN};
  letter-spacing: 0.08em;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  width: 90%;
  text-align: center;
`
export const SectionTitle = css`
  text-align: center;
  font-size: 3.6rem;
  line-height: 53px;
  font-weight: 600;
  letter-spacing: 0.04em;
  padding-bottom: 40px;
  border-bottom: 4px solid ${colors.cl_red};
  margin-bottom: 60px;
  ${breakpoints.lessThan('pc')`
  text-align: left;
  font-size: 2.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  padding-bottom: 20px;
  margin-bottom: 0;
`}
`
export const HeadingWithLeftbar = color => css`
  padding-left: 8px;
  font-size: 2.4rem;
  line-height: 36px;
  border-left: 4px solid ${colors.cl_red};
  color: ${colors.cl_red};
  letter-spacing: 0.04em;
  ${breakpoints.lessThan('pc')`
  padding-left: 6px;
  font-size: 1.8rem;
  line-height: 27px;
`}
`

export const HeadingWithUnderbar = color => css`
  h3 {
    ${DIN};
    ${HeadingBaseStyle};
    display: inline-block;
    padding-bottom: 8px;
    position: relative;
    color: ${color};
    border-bottom: 2px solid ${color};
  }
  span {
    color: ${color};
    top: 2.8em;
    font-size: 1.4rem;
    text-align: center;
    padding-top: 12px;
  }
`

export const ArticleTextStyle = css`
  font-size: 1.8rem;
  line-height: 180%;
  ${breakpoints.lessThan('pc')`
  font-size: 1.4rem;
`}
`
