import React from 'react'
import styled from 'styled-components'
import { HeadingWithUnderbar } from '../tokens/fontStyles'
import { alignVertical } from '../tokens/positionings'

const Component = ({ title, subTitle, margin, color }) => {
  return (
    <Wrapper margin={margin} color={color}>
      <h3>{title}</h3>
      <span>{subTitle}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${props => props.margin}px;
  ${HeadingWithUnderbar(props => props.color)};
  ${alignVertical};
`

export default Component
