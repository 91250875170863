import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import breakpoints from '../breakpoints'
import { flex, alignCenter } from '../tokens/positionings'
import { DIN } from '../tokens/fontStyles'
import colors from '../tokens/colors'

const Component = props => {
  const lists = []
  for (let list of props.data) {
    lists.push(
      <List>
        <StyledLink
          className={'glist__link glist__link--' + list.link}
          to={'/' + list.link}
          title={list.text}
        >
          {list.text}
        </StyledLink>
      </List>
    )
  }
  return <React.Fragment>{lists}</React.Fragment>
}

const List = styled.li`
  height: 100%;
  margin-right: 40px;
  ${flex('center')}
  ${alignCenter}
  ${breakpoints.lessThan('pc')`
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 20px;
    height: auto;
  `}
`

const StyledLink = styled(Link)`
  ${DIN}
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  :hover {
    color: ${colors.cl_red};
    font-weight: bold;
  }
  ${breakpoints.lessThan('pc')`
  `}
`

export default Component
